












































































































































import {Component, Prop} from 'vue-property-decorator';
import {Project} from "@/data/model/Project";
import CommentsView from "@/components/CommentsView.vue";
import Base from "@/data/model/Base";
import {OrderElement, OrderTypes} from "@/data/model/OrderElement";
import Tooltip from "@/components/Tooltip.vue";


@Component({
  components: {Tooltip, CommentsView},
})
export default class ProjectOverview extends Base {
  @Prop({required: true})
  public project!: Project

  constructor() {
    super('project-overview')
  }

  get orderTypes() {
    return OrderTypes
  }

  get supportStructure() {
    return this.project.order!.filter(orderElement => orderElement.name === OrderTypes.STATIC || orderElement.name === OrderTypes.PROBATIO_PLANS)
  }

  get structuralPhysics() {
    return this.project.order!.filter(orderElement => !(orderElement.name === OrderTypes.STATIC || orderElement.name === OrderTypes.PROBATIO_PLANS || orderElement.name === OrderTypes.CONSTRUCTION_SUPERVISION))
  }

  get construction(){
    return this.project.order!.filter(orderElement => (orderElement.name === OrderTypes.CONSTRUCTION_SUPERVISION))
  }

  get supportStructureInfos(): any {
    return [
      {name: this.getLangText('startDate'), value: this.makePretty(this.project.startDate)},
      {name: this.getLangText('estimatedDeliveryDate'), value: this.getWeekNumber(this.project.estimatedDeliveryDate)},
      {name: this.getLangText('deliveryDate'), value: this.makePretty(this.project.deliveryDate)},
    ]
  }

  makePretty(date?: string) {
    return this.$options.filters!.prettyDate(date)
  }

  get isAdmin(){
    return this.$store.state.applicationUser.role === 'ADMIN'
  }

  private getresponsibleText(order:OrderElement){
    if(this.isAdmin){
      if(order.responsibleStartDate){
        return order.responsible + ' ab ' + this.makePretty(order.responsibleStartDate)
      } else return order.responsible
    }else {
       return order.responsible
    }
  }

  public getGenerellInfos(): any {
    return [
      {name: this.getLangText('id'), value: this.project.id},
      {name: this.getLangText('addressline'), value: this.project.address?.[0].addressline[0]},
      {name: this.getLangText('type'), value: this.project.type},
      {name: this.getLangTextWithoutPrefix('status.label'), value: this.project.status},
    ]
  }
}
