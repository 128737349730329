




















































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Comment, Project, CommentType} from "@/data/model/Project";
import Base from "@/data/model/Base";

@Component({})
export default class CommentsView extends Base {
  @Prop({required: true})
  public project!: Project

  @Prop({required: false, default: false})
  public editable!: boolean

  public showAddView = false
  public addCommentText = ""
  public addAuthor = ""

  constructor() {
    super('comments-view')
  }

  get commentType() {
    return CommentType
  }

  get sortedCommentsByCreationdate() {
    let comments = this.project.comments!.sort((a: Comment, b: Comment) => {
      return this.getTime(b.creationDate) - this.getTime(a.creationDate);
    });

    if(this.isAdmin){
      return comments
    } else {
      return comments.filter(c => c.isExtern === true)
    }
  }

  get isAdmin(){
    return this.$store.state.applicationUser.role === 'ADMIN'
  }

  mounted() {
    this.addAuthor = this.$store.state.applicationUser.name
  }

  private getTime(date?: string) {
    return date != null ? new Date(Date.parse(date)).getTime() : 0;
  }

  private deleteComment(comment: Comment) {
    this.project.comments?.forEach((value, index) => {
      if (value == comment) this.project.comments?.splice(index, 1);
    });
  }

  private addComment(isExtern: boolean) {
    if (!this.project.comments) this.project.comments = new Array<Comment>()

    this.project.comments?.push({
      text: this.addCommentText,
      creationDate: new Date(Date.now()).toISOString(),
      author: this.addAuthor,
      isExtern: isExtern
    })

    this.addCommentText = ''
    this.showAddView = false
  }

}
