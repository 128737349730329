import {Address} from "@/data/model/Address";
import {OrderElement} from "@/data/model/OrderElement";

export interface Project {
    name: string
    id: string
    address?: Array<Address>
    type?: string
    startDate?: string
    deliveryDate?: string
    estimatedDeliveryDate?: string
    order?: Array<OrderElement> //Bestellungen/Beauftragungen
    comment?:string
    comments?: Array<Comment>
    status?: Status
    noteSignal: 'success' | 'warning' | 'error' | 'white'
}

export interface Comment {
    creationDate: string,
    text: string,
    author: string,
    type?: CommentType,
    isExtern: boolean
}

export enum CommentType {
    WARING = 'WARING'
}

export enum Status {
    RECEIVED= 'RECEIVED',
    PREPARED_AND_READY = 'PREPARED_AND_READY',
    PREPARED_AND_INCOMPLETE = 'PREPARED_AND_INCOMPLETE',
    IN_PROGRESS= 'IN_PROGRESS',
    BLOCKED = 'BLOCKED',
    COMPLETED = 'COMPLETED'
}

export namespace Status {
    export function getValue(s: any) {
        let values = Object.values(Status);
        let keys = Object.keys(Status);
        if(!keys) return ''
        if(!s) return ''
        for (let i = 0; i < values.length-1 ; i++) {
            if(keys[i].toLowerCase() === s.toLowerCase()){
                return values[i]
            }
        }
        return ''
    }
}
